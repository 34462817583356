@import 'reset';

$dark: #242733;
$light: #fffcf9;

$size: 50vmin;
$rows: 14;
$columns: 14;

:root {
  --color-text: #{$dark};
  --color-background: #{$light};
  --color-background-puzzle: #{desaturate(darken($light, 5%), 50%)};
  --color-input-border: #{desaturate(darken($light, 10%), 50%)};
  --color-highlight: #14D8F1;

  @media (prefers-color-scheme: dark) {
    --color-text: #{$light};
    --color-background: #{$dark};
    --color-background-puzzle: #{lighten($dark, 10%)};
    --color-input-border: #{lighten($dark, 40%)};
  }
}

html,
body,
.page {
  height: 100%;
}

body {
  padding: 1em;
  background: var(--color-background);
  font-family: Helvetica, Ariel, sans-serif;
  color: var(--color-text);
  font-size: clamp(18px, 5vmin, 32px);
  text-align: center;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

::selection {
  background-color: var(--color-highlight);
}

a,
button {
  color: inherit;
  text-decoration-color: var(--color-highlight);
  text-decoration-style: solid;
  outline: none;

  &:focus {
    text-decoration-style: dotted;
  }
}

.no-wrap {
  white-space: nowrap;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro p {
  margin-top: 0.2em;
}

.puzzle__wrapper {
  --size: clamp(200px, 50vh, 80vw);
  width: var(--size);
  height: var(--size);
  position: relative;
  margin: 0.5em auto;
  padding: 0.2em;
  background: var(--color-background-puzzle);
}

.puzzle {
  width: 100%;
  height: 100%;
  overflow: visible;
  opacity: 1;
  transition: opacity 500ms linear;

  &.js-hidden {
    opacity: 0;
  }

  path {
    fill: url(#puzzle-pattern);
    stroke: var(--color-background-puzzle);
    stroke-width: 0.2%;
  }
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
}

.links {
  margin-bottom: 0.5em;

  li {
    display: inline-block;
  }

  li + li {
    margin-left: 0.75em;
  }

  a:focus {
    color: var(--color-highlight);
  }

  svg {
    width: 1.5em;
    height: 1.5em;
    vertical-align: bottom;
  }
}

.newsletter {
  font-size: clamp(18px, 0.75em, 20px);
}

.newsletter__input {
  width: clamp(200px, 80vw, 15ch);
  border: 2px solid var(--color-input-border);
  outline: none;
  margin: 0.5em 0;
  background: var(--color-background);

  &:focus {
    border-color: var(--color-highlight);
  }
}

.disclaimer {
  font-size: 0.5em;
  font-style: italic;
}

.js-avoid {
  cursor: initial;
}
